/* eslint-disable react/react-in-jsx-scope */
import useTheme from 'hooks/useTheme'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Menu } from 'antd'
import {
  MoneyCollectOutlined,
  SwapOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SnippetsOutlined,
  BookOutlined,
  ClusterOutlined,
  DollarOutlined,
  FormatPainterOutlined,
  LikeOutlined,
  InteractionOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useHistory, useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import ModalDeposit from 'components/ModalDepositQR'
import { useWeb3React } from '@web3-react/core'
import { ADDRESS_ADMIN } from 'constants/index'

const heightHeader = '70px'

const WrapperHeader = styled.div`
  position: relative;
  z-index: 10;
  width: calc(100vw - 250px);
  margin-left: 250px;

  &.theme-dark {
    background-color: rgb(20 15 52 / 1);

    * {
      color: #fff;
    }
  }

  .wrap-content-header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    margin-left: 0;
    background-color: rgb(20 15 52 / 1);

    ${({ theme }) => theme.mediaQueries.lg} {
      margin-left: 250px;
      width: calc(100vw - 250px);
    }
  }

  .content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${heightHeader};
    padding-left: 2rem;
    padding-right: 2rem;

    .wrap-title {
      display: flex;
      align-items: center;

      h4 {
        margin-right: 10px;

        ${({ theme }) => theme.mediaQueries.lg} {
          margin-right: 25px;
        }
      }

      button {
        &:not(:last-child) {
          margin-right: 10px;

          ${({ theme }) => theme.mediaQueries.lg} {
            margin-right: 25px;
          }
        }
      }

      .wrap-price {
        font-size: 1.5rem;
      }
    }
  }

  .clone-header {
    height: ${heightHeader};
  }
`

const ContentMenu = styled.div`
  &.theme-dark {
    .content-menu {
      background-color: rgb(20 15 52 / 1);

      * {
        color: #fff;
      }
    }
  }

  .content-menu {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 250px;
    background: #000000;
    min-height: 100vh;
    height: 100%;
    overflow: auto;
    transform: translateX(-100%);
    transition: 0.25s;

    &.active {
      transform: translateX(0);
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      transform: translateX(0);
    }

    .logo-menu {
      text-align: center;
      padding: 10px 20px 20px 20px;
    }

    .ant-menu {
      &.ant-menu-dark {
        background-color: rgb(20 15 52 / 1);

        .ant-menu-inline.ant-menu-sub {
          background-color: rgb(20 15 52 / 1);
        }

        &:not(.ant-menu-horizontal) {
          .ant-menu-item-selected {
            background-color: transparent;

            .ant-menu-title-content {
              font-weight: bold;
              color: #fff;
            }
          }
        }

        .ant-menu-title-content {
          color: #d3d1ff;
        }
      }
    }

    .wrap-btn-connect-mb {
      button {
        width: 100%;
      }
    }

    .btn-deposit {
      font-weight: 600;
      border-radius: 0.75rem;
      border-width: 2px;
      min-height: 40px;
      height: 100%;
      background: #0063f7;
      padding: 0;
      width: 100%;
    }

    .wrap-social {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0 10px 0;

      .social-item {
        text-align: center;

        &:not(:last-child) {
          margin-right: 16px;
        }

        img {
          max-width: 100%;
          width: 35px;
        }
      }
    }

    .email-text {
      text-align: center;
      font-size: 13px;
      padding: 0 0 20px 0;
    }
  }
`

const NotiPage = styled.div`
  padding: 2px 15px;
  line-height: 150%;
  background: rgba(220, 53, 69, 1);
  font-size: 13px;

  ${({ theme }) => theme.mediaQueries.lg} {
    width: calc(100vw - 250px);
  }

  .marquee {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    animation: scroll 20s linear infinite;

    ${({ theme }) => theme.mediaQueries.lg} {
      white-space: break-spaces;
      animation: unset;
    }
  }

  .marquee::after {
    content: ' ';
  }

  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`

const PRICE_PBC = 0.0003

export default function Header() {
  const { isDark } = useTheme()
  const { account } = useWeb3React()

  const [collapsed] = useState(false)
  const [active, setActiveMenu] = useState<string>('assets')
  const [open, setOpenMenu] = useState<boolean>(false)
  const [toggleModalDeposit, setToggleModalDeposit] = useState<boolean>(false)

  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname) {
      setActiveMenu(location.pathname)
    }
  }, [location.pathname])

  const handelLink = (link: string) => {
    history.push(link)
    setOpenMenu(false)
  }

  useEffect(() => {
    if (account && ADDRESS_ADMIN.toLowerCase().split(',').includes(account?.toLowerCase())) {
      history.push('/admin/dashboard')
    }
  }, [account, history])

  return (
    <>
      <WrapperHeader className={isDark ? 'theme-dark' : ''}>
        <div className="wrap-content-header">
          <NotiPage>
            <p className="marquee">
              We are upgrading the web3 system to version 2, for listing on international exchanges in Q1 2025. The update is
              expected to take place in the next 2 to 3 days.
            </p>
          </NotiPage>

          <div className="content-header">
            <div className="wrap-title">
              <h4>
                {active === '/' && 'My Assets'}
                {active === '/swap' && 'Swap'}
                {active === '/swap/system' && 'Swap'}
                {active === '/packages' && 'Packages'}
                {active === '/my-investments' && 'My Invest'}
                {active === '/referral' && 'Referral'}
                {active === '/admin/dashboard' && 'Dashboard'}
                {active === '/admin/package' && 'Package Active'}
                {active === '/admin/user' && 'User'}
                {active === '/admin/referral' && 'Referral'}
              </h4>

              {/* {active === 'assets' && !isMobile && (
              <>
                <Button type="primary" onClick={() => setToggleModalDeposit(true)}>
                  Deposit USDT & invest now
                </Button>
              </>
            )} */}

              {((account && !ADDRESS_ADMIN.toLowerCase().split(',').includes(account?.toLowerCase()) && !isMobile) ||
                (!account && !isMobile)) && (
                <div className="wrap-price">
                  <p>PBC = {PRICE_PBC}$</p>
                </div>
              )}
            </div>

            {!isMobile && <ConnectWalletButton />}

            {isMobile && (
              <button type="button" onClick={() => setOpenMenu((prev) => !prev)}>
                {open ? <MenuUnfoldOutlined style={{ fontSize: '26px' }} /> : <MenuFoldOutlined style={{ fontSize: '26px' }} />}
              </button>
            )}
          </div>
        </div>
        <div className="clone-header" />
      </WrapperHeader>

      <ContentMenu className={isDark ? 'theme-dark' : ''}>
        <div className={`content-menu ${isDark ? 'theme-dark' : ''} ${open ? 'active' : ''}`}>
          <div className="logo-menu">
            <img src="/images/logo.png" width={70} alt="" />
          </div>

          <Menu selectedKeys={[active]} defaultOpenKeys={['menu-swap']} mode="inline" theme="dark" inlineCollapsed={collapsed}>
            {account && ADDRESS_ADMIN.toLowerCase().split(',').includes(account?.toLowerCase()) ? (
              <>
                <Menu.Item key="/admin/dashboard" onClick={() => handelLink('/admin/dashboard')}>
                  Dashboard
                </Menu.Item>
                <Menu.Item key="/admin/package" onClick={() => handelLink('/admin/package')}>
                  Package Active
                </Menu.Item>
                <Menu.Item key="/admin/user" onClick={() => handelLink('/admin/user')}>
                  User
                </Menu.Item>
                <Menu.Item key="/admin/referral" onClick={() => handelLink('/admin/referral')}>
                  Referral
                </Menu.Item>
              </>
            ) : (
              <>
                <Menu.Item key="/" icon={<MoneyCollectOutlined />} onClick={() => handelLink('/')}>
                  Assets
                </Menu.Item>
                <Menu.SubMenu key="/menu-swap" icon={<SwapOutlined />} title="Swap">
                  <Menu.Item key="/swap" onClick={() => handelLink('/swap')}>
                    On Chain
                  </Menu.Item>
                  <Menu.Item key="/swap/system" onClick={() => handelLink('/swap/system')}>
                    PBC
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.Item key="/packages" icon={<BookOutlined />} onClick={() => handelLink('/packages')}>
                  Package
                </Menu.Item>
                <Menu.Item key="/my-investments" icon={<BookOutlined />} onClick={() => handelLink('/my-investments')}>
                  My Investments
                </Menu.Item>
                <Menu.Item key="/referral" icon={<ClusterOutlined />} onClick={() => handelLink('/referral')}>
                  Referral
                </Menu.Item>
                <Menu.Item
                  key="whitespaper"
                  icon={<SnippetsOutlined />}
                  onClick={() => window.open('/whitespaper.pdf?v=1.2', '_blank', 'noopener,noreferrer')}
                >
                  Whitepaper
                </Menu.Item>
                <Menu.Item key="/farms" icon={<SnippetsOutlined />}>
                  Farms <span style={{ fontSize: '10px' }}>(Coming soon)</span>
                </Menu.Item>
                <Menu.Item key="/staking" icon={<DollarOutlined />}>
                  PBC Staking <span style={{ fontSize: '10px' }}>(Coming soon)</span>
                </Menu.Item>
                <Menu.Item key="/pools" icon={<DollarOutlined />}>
                  Pools <span style={{ fontSize: '10px' }}>(Coming soon)</span>
                </Menu.Item>
                <Menu.Item key="/game-fi" icon={<FormatPainterOutlined />}>
                  GameFi <span style={{ fontSize: '10px' }}>(Coming soon)</span>
                </Menu.Item>
                <Menu.Item key="/voting" icon={<LikeOutlined />}>
                  Voting <span style={{ fontSize: '10px' }}>(Coming soon)</span>
                </Menu.Item>
                <Menu.Item key="/exchange" icon={<InteractionOutlined />}>
                  Exchange <span style={{ fontSize: '10px' }}>(Coming soon)</span>
                </Menu.Item>
                <Menu.Item key="/marketplace" icon={<ShoppingCartOutlined />}>
                  NFT Marketplace <span style={{ fontSize: '10px' }}>(Coming soon)</span>
                </Menu.Item>

                {isMobile && (
                  <>
                    <Menu.Item>
                      <p>PBC = {PRICE_PBC}$</p>
                    </Menu.Item>
                    <Menu.Item>
                      <button type="button" className="btn-deposit" onClick={() => setToggleModalDeposit(true)}>
                        Deposit USDT & invest now
                      </button>
                    </Menu.Item>
                    <Menu.Item>
                      <div className="wrap-btn-connect-mb">
                        <ConnectWalletButton />
                      </div>
                    </Menu.Item>
                  </>
                )}
              </>
            )}
          </Menu>

          {!(account && ADDRESS_ADMIN.toLowerCase().split(',').includes(account?.toLowerCase())) && (
            <>
              <div className="wrap-social">
                <div className="social-item">
                  <a href="https://x.com/_Public_Wallet" target="_Blank" rel="noreferrer">
                    <img src="/images/icons/twitterx.svg" alt="" />
                  </a>
                </div>
                <div className="social-item">
                  <a href="https://t.me/Public_Wallet_Channel" target="_Blank" rel="noreferrer">
                    <img src="/images/icons/telegram-logo.svg" alt="" />
                  </a>
                </div>
              </div>

              <div className="email-text">
                <a href="mailto:support@public-wallet.net">support@public-wallet.net</a>
              </div>
            </>
          )}
        </div>
      </ContentMenu>

      <ModalDeposit toggle={toggleModalDeposit} setToggle={setToggleModalDeposit} />
    </>
  )
}
